/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useCallback } from "react";
import {
  Icon,
  ButtonGroup,
  Button,
  Modal,
  Link,
  DisplayText,
  TextContainer,
  Heading,
  TextStyle,
  Card,
  SkeletonBodyText,
  Stack,
  Banner,
  List,
  Tooltip,
} from "@shopify/polaris";
import { TickMinor, CancelSmallMinor, MinusMinor, InfoMinor } from "@shopify/polaris-icons";
import { useHistory } from "react-router-dom";

import { useFetch, useProfile, useRedirect } from "../utils";
import { SubscriptionContext } from "../contexts/SubscriptionContext";
import { useCreateModal, useCreateToast } from "../contexts/OverlayManager";

import "./PlanFeatureMatrix.scss";

const TRIAL_PLAN_ID = 8;

const comparePlans = (a, b) => {
  const featureList = Object.entries(b.features)
    .filter(([, d]) => d.display && d.display?.rank)
    .sort((aa, bb) => aa[1].display.rank - bb[1].display.rank);

  const missingFeatures = featureList.filter(([key, data]) => !a.features[key] || a.features[key].value < data.value);
  const commonFeatures = featureList.filter(([k]) => !Object.prototype.hasOwnProperty.call(Object.fromEntries(missingFeatures), k));

  return { missingFeatures, commonFeatures };
};

const TrialDaysText = ({ plan, activePlan }) => {
  const [profile] = useProfile();
  const p = plan;

  if (Math.max(0, p.trial_days - profile?.shop?.trial_days_used) === 0) return null;

  if (activePlan) {
    return <p>{`${Math.max(0, p.trial_days - profile?.shop?.trial_days_used)} Trial Days Remaining`}</p>;
  }

  return <p>{`${Math.max(0, p.trial_days - profile?.shop?.trial_days_used)} Day Free Trial`}</p>;
};

const FeatureTable = ({ plans, activeSubscriptions = null, highlightFeature, highlightFeatureValue, subscribe }) => {
  const activePlan = plans.find(({ id }) => id === activeSubscriptions.find((s) => s.plan_id !== -1)?.plan_id);
  const [profile] = useProfile();
  const history = useHistory();
  const [annual, setAnnual] = useState(false);

  const isOnTrialPlan = activePlan?.id === TRIAL_PLAN_ID; // make me better with this nonsense

  let filteredPlans = plans.filter((p) => (annual && p.annual_cost !== null) || (!annual && p.monthly_cost !== null)).filter((p) => p.id !== TRIAL_PLAN_ID);
  let featureList = Object.entries(plans[0]?.features)
    .filter(([, d]) => d.display && d.display?.rank)
    .sort((a, b) => a[1].display.rank - b[1].display.rank);
  let missingFeatures = [];
  let commonFeatures = [];

  if (highlightFeature && plans.length) {
    filteredPlans = plans.filter((p) => {
      const planFeatureValue = p.features[highlightFeature]?.value;
      switch (typeof planFeatureValue) {
        case "number":
          return planFeatureValue >= Number(highlightFeatureValue);
        case "boolean":
          return planFeatureValue >= Boolean(highlightFeatureValue);
        default:
          return false;
      }
    });

    if (filteredPlans) {
      featureList = featureList.filter(([k]) => k !== highlightFeature);
      missingFeatures = featureList.filter(
        ([key, data]) => !activePlan.features[key] || activePlan.features[key].value < data.value
      );
      commonFeatures = featureList.filter(([k]) => !Object.prototype.hasOwnProperty.call(Object.fromEntries(missingFeatures), k));
    }
  }

  if (activePlan && highlightFeature) filteredPlans.unshift(activePlan);

  const renderRow = ([featureName, featureValue]) => (
    <tr
      key={`FM-row-${featureName}`}
      className={
        featureName === highlightFeature
          ? "Polaris-IndexTable__TableRow Polaris-IndexTable__TableRow--selected"
          : "Polaris-IndexTable__TableRow"
      }
    >
      <td className="Polaris-IndexTable__TableCell" key={`FM-row-${featureName}-f`}>
        {featureValue?.display?.key ? featureValue.display.key : featureName}
      </td>
      {filteredPlans.map((p, cindex) => {
        let contents = p?.features[featureName]?.display?.value
          ? p.features[featureName].display.value
          : p?.features[featureName]?.value;

        if (typeof contents === "boolean") {
          contents = contents ? <Icon source={TickMinor} color="success" /> : <Icon source={CancelSmallMinor} color="critical" />;
        } else if (p.features[featureName] && p.features[featureName].display?.info) {
          contents = (
            <span style={{ display: "inline-flex" }}>
              <Tooltip content={p.features[featureName]?.display?.info}>
                <Icon source={InfoMinor} />
              </Tooltip>
              <TextStyle variation="strong">{contents}</TextStyle>
            </span>
          );
        } else {
          contents = contents === null ? <Icon source={MinusMinor} color="new" /> : <TextStyle variation="strong">{contents}</TextStyle>;
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <td className="Polaris-IndexTable__TableCell" key={`FM-row-${featureName}-${cindex}`}>
            {contents}
          </td>
        );
      })}
    </tr>
  );

  const upgradeText = (plan) => {
    if (plan.custom && !profile?.user?.support) return "Contact Us!";
    if (activePlan && !isOnTrialPlan) {
      if (plan.id === activePlan?.id) return "Current Plan";
      return activePlan.display_ranking <= plan.display_ranking ? "Upgrade" : "Downgrade";
    }
    return "Subscribe";
  };

  return (
    <Card
      actions={
        plans.filter((p) => p.annual_cost !== null).length > 0 && [
          {
            content: (
              <ButtonGroup segmented>
                <Button
                  pressed={annual}
                  onClick={() => {
                    setAnnual(true);
                  }}
                  outline
                >
                  Annual
                </Button>
                <Button
                  pressed={!annual}
                  onClick={() => {
                    setAnnual(false);
                  }}
                  outline
                >
                  Monthly
                </Button>
              </ButtonGroup>
            ),
          },
        ]
      }
    >
      {highlightFeature && highlightFeatureValue && (
        <Card.Section>
          <Banner
            onDismiss={() => {
              history.replace("/account");
            }}
          >
            {activePlan.features[highlightFeature]?.display.description && (
              <p>{`${activePlan.features[highlightFeature].display?.description}`}</p>
            )}
            <p>clear comparison</p>
          </Banner>
        </Card.Section>
      )}
      <Card.Section flush>
        <div className="plan-feature-matrix">
          <table className="Polaris-IndexTable__Table">
            <thead>
              <tr>
                <th aria-label="Feature Name" />
                {filteredPlans.map((p, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <th className="Polaris-IndexTable__TableHeading" key={`FM-header-col-${index}`}>
                    <Stack vertical>
                      <DisplayText size="medium">{p.name}</DisplayText>
                      <DisplayText size="small">
                        {p.custom && !profile?.user?.support ? "Custom" : `USD $${annual ? p.annual_cost / 12 : p.monthly_cost}`}
                      </DisplayText>
                      <TrialDaysText plan={p} activePlan={activePlan} />
                      <Button
                        onClick={() => {
                          subscribe(p, annual);
                        }}
                        primary
                        fullWidth
                        disabled={p.id === activePlan?.id || (p.custom && !profile?.user?.support)}
                        size="large"
                      >
                        {upgradeText(p)}
                      </Button>
                    </Stack>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!highlightFeature && featureList.map(renderRow)}
              {highlightFeature && renderRow([highlightFeature, activePlan.features[highlightFeature]])}
              {highlightFeature && missingFeatures.map(renderRow)}
              {highlightFeature && commonFeatures.map(renderRow)}
            </tbody>
          </table>
        </div>
      </Card.Section>
    </Card>
  );
};

export default ({ highlightFeature, featureValue: highlightFeatureValue, termsOfService }) => {
  const { plans, activePlan, reload, activeSubscriptions, loaded } = useContext(SubscriptionContext);
  const authFetch = useFetch();
  const [profile] = useProfile();
  const redirect = useRedirect();
  const createModal = useCreateModal();
  const createToast = useCreateToast();

  const isOnTrialPlan = activePlan?.id === 8;

  const doSubscribe = useCallback(
    (selectedPlanId, annual) =>
      authFetch("/api/subscriptions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ subscription: { plan_id: selectedPlanId, annual } }),
      })
        .then(async (response) => {
          const data = await response.json();
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          if (profile.user.account_owner && data.redirect_to) {
            redirect(data.redirect_to);
            reload();
            return Promise.resolve(true);
          }
          if (data?.redirect_to) {
            createModal({
              title: "Approve Charge",
              secondaryActions: [{ content: "Close" }],
              render: () => (
                <Modal.Section>
                  <TextContainer>
                    <Heading>One More Thing...</Heading>
                    <p>
                      In order to start your subscription you&apos;ll need the store owner to approve the charge we just created.
                      According to our records {profile.shop.shop_owner} is the store owner, and their email is {profile.shop.email}
                      . You&apos;ll need to have them go to the following url to approve the charge. If you have the appropriate
                      permissions, it&apos;s possible that you can click on the link and approve the charge yourself.
                    </p>
                    <br />
                    <Link external url={data.redirect_to}>
                      {data.redirect_to}
                    </Link>
                  </TextContainer>
                </Modal.Section>
              ),
            });
          } else if (data?.message) {
            createToast({ content: `${data.message}` });
          }
          reload();
          return Promise.resolve(true);
        })
        .catch((error) => {
          // TODO catch error this.setState({ errorMessage: error.toString() });
          // eslint-disable-next-line no-console
          console.error("There was an error!", error);
          createModal({
            title: "Error",
            primaryAction: { content: "Close" },
            render: () => (
              <Modal.Section>
                <DisplayText>There was an error in subscribing. Please try again later.</DisplayText>
              </Modal.Section>
            ),
          });
          return false;
        }),
    [authFetch, profile.user.account_owner, profile.shop.shop_owner, profile.shop.email, reload, redirect, createModal, createToast]
  );

  const subscribe = useCallback(
    (plan, annual) => {
      const isDowngrade = activePlan?.display_ranking > plan?.display_ranking;

      if (isDowngrade) {
        createModal({
          title: "Downgrade",
          primaryAction: {
            content: "Downgrade Now",
            onAction: () => doSubscribe(plan.id, annual),
          },
          secondaryActions: [{ content: "Cancel" }],
          render: () => (
            <Modal.Section>
              <p>You are downgrading you are going to be losing the following features</p>
              <br />
              <List type="bullet">
                {activePlan &&
                  comparePlans(plan, activePlan).missingFeatures.map(([key, { display, value }]) => (
                    <List.Item>
                      {display.key}
                      {typeof value === "number" &&
                        ` from ${activePlan.features[key].display.value} to ${plan.features[key].display.value}`}
                    </List.Item>
                  ))}
              </List>
              <br />
              <p>After changing your plan you will have to remove any features you no longer have access to.</p>
            </Modal.Section>
          ),
        });
        return Promise.resolve(true);
      }
      return doSubscribe(plan.id, annual);
    },
    [activePlan, doSubscribe, createModal]
  );

  if (!loaded) {
    return (
      <Card>
        <SkeletonBodyText lines={10} />
      </Card>
    );
  }

  return (
    <>
      {isOnTrialPlan && (
        <>
          <Banner status="warning">
            <p>WOW much trial; pls give us money we give you service</p>
          </Banner>
          <br />
        </>
      )}
      <FeatureTable
        plans={plans}
        activeSubscriptions={activeSubscriptions}
        highlightFeature={highlightFeature}
        highlightFeatureValue={highlightFeatureValue}
        subscribe={(p, a) => {
          if (termsOfService && !activeSubscriptions[0]) {
            createModal({
              title: "Terms of Service",
              primaryAction: {
                content: "Accept",
                onAction: () => subscribe(p, a),
              },
              secondaryActions: [{ content: "Cancel" }],
              render: () => termsOfService,
            });
          } else {
            subscribe(p, a);
          }
        }}
      />
    </>
  );
};
